const b2b = {
  methods: {
    makeEventBooking(){
      let formData = new FormData();
      this.showLoader();
      if (
          this.booking.upload &&
          this.booking.participant_document != null &&
          typeof this.booking.participant_document != "string"
      ) {
        formData.append(
            "participant_document",
            this.booking.participant_document
        );
      } else {
        this.booking.customers.forEach((element, index) => {
          formData.append(`customers[${index}][name]`, element.name);
          formData.append(`customers[${index}][email]`, element.email);
          formData.append(`customers[${index}][phone]`, element.phone);
          if (element.gender){
            formData.append(`customers[${index}][gender]`, element.gender);
          }
          if (element.dob){
            formData.append(`customers[${index}][dob]`, element.dob);
          }
          if (element.nationality){
            formData.append(`customers[${index}][nationality]`, element.nationality);
          }
        });
      }

      formData.append("booking_type", this.booking.booking_type);
      formData.append("date", this.booking.booking_date);
      formData.append("products[0][product_id]", this.booking.ticket);
      formData.append("products[0][product_id]", this.booking.ticket);
      formData.append("products[0][quantity]", this.booking.quantity);
      formData.append("event_id", this.booking.event_id);
      formData.append("entity_id", this.b2bId);
      formData.append("customers_count", this.booking.customer_count);
      this.$http
          .post("venues/b2b/booking/events", formData, {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          })
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              let message = response.data.message;
              this.showSuccess(message);
              this.hideLoader();
              this.closeDialogue();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    makeBooking(e) {
      if (e != null) {
        e.preventDefault();
        if (!this.$refs.form.validate()) {
          this.showError("Please fill all required fields");
          return;
        }
      }
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }

      if (this.booking.booking_type === 3) {
        return this.makeEventBooking();
      }

      if (
        this.max_capacity < this.available_capacity &&
        this.enablePerDayCapacity
      ) {
        this.showError("Venue has no capacity available");
        return;
      }
      let formData = new FormData();
      this.showLoader();
      if (
        this.booking.upload &&
        this.booking.participant_document != null &&
        typeof this.booking.participant_document != "string"
      ) {
        formData.append(
          "participant_document",
          this.booking.participant_document
        );
      } else {
        this.booking.customers.forEach((element, index) => {
          formData.append(`customers[${index}][name]`, element.name);
          formData.append(`customers[${index}][email]`, element.email);
          formData.append(`customers[${index}][phone]`, element.phone);
          if (element.gender){
            formData.append(`customers[${index}][gender]`, element.gender);
          }
          if (element.dob){
            formData.append(`customers[${index}][dob]`, element.dob);
          }
          if (element.nationality && element.id_proof_number != "null"){
            formData.append(`customers[${index}][nationality]`, element.nationality);
          }
          if (element.id_proof_type_id && element.id_proof_number != "null"){
            formData.append(`customers[${index}][id_proof_type_id]`, element.id_proof_type_id);
          }
          if (element.id_proof_number && element.id_proof_number != "null"){
            formData.append(`customers[${index}][id_proof_number]`, element.id_proof_number);
          }
          if (element.gender && element.gender != "null" ){
            formData.append(`customers[${index}][gender]`, element.gender);
          }
          if (element.customer_tag && element.customer_tag != "null" ){
            formData.append(`customers[${index}][customer_tag]`, element.customer_tag);
          }
        });
      }
      formData.append("booking_type", this.booking.booking_type);
      formData.append("date", this.booking.booking_date);
      formData.append("is_open_dated", this.booking.open_dated);
      formData.append("from_time", this.booking.from_time);
      formData.append("to_time", this.booking.to_time);
      formData.append("service_type", this.booking.booking_type);
      formData.append("venue_service_id", this.booking.product_type);
      formData.append("products[0][product_id]", this.booking.product);
      formData.append("products[0][quantity]", this.booking.quantity);
      formData.append("facility_id", this.booking.facility_id);
      formData.append("entity_id", this.b2bId);
      formData.append("available_capacity", this.available_capacity);
      formData.append("customers_count", this.booking.customer_count);
      this.$http
        .post("venues/b2b/booking", formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let message = response.data.message;
            this.showSuccess(message);
            this.hideLoader();
            this.closeDialogue();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addOrEditEntity(e, flag) {
      if (e != null) {
        e.preventDefault();
        if (!this.$refs.form.validate()) {
          this.showError("Please fill all required fields");
          return;
        }
      }
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let formData = new FormData();
      this.showLoader();
      if (
        this.entity.profile_image != null &&
        typeof this.entity.profile_image != "string"
      ) {
        formData.append("profile_image", this.entity.profile_image);
      }
      formData.append("entity_name", this.entity.entity_name);
      formData.append("enable_commission", this.entity.enable_commission);
      if (this.entity.enable_commission == 1) {
        formData.append(
          "commission_percentage",
          this.entity.commission_percentage
        );
      } else {
        formData.append("commission_percentage", 0);
      }
      formData.append("b2b_type_id", this.entity.b2b_type_id);
      formData.append("contact_person_name", this.entity.contact_person_name);
      formData.append("phone", this.entity.phone);
      formData.append("trn_number", this.entity.trn_number);
      formData.append("trade_license_no", this.entity.trade_license_no);
      formData.append("description", this.entity.description);
      formData.append("city_id", this.entity.city_id);
      formData.append("sector", this.entity.sector);
      formData.append("email", this.entity.email);
      formData.append("allow_credit", this.entity.allow_credit);
      formData.append("credit_limit", this.entity.credit_limit);
      var document = [];
      this.documentFields.forEach((documentField) => {
        if (documentField.file || documentField.isEdit) {
          document.push(documentField);
        }
      });

      document.forEach((element, index) => {
        formData.append(`documents[${index}][name]`, element.name);
        formData.append(`documents[${index}][id]`, element.id);
        if (element.isEdit) {
          formData.append(`documents[${index}][file_path]`, element.file_path);
          formData.append(
            `documents[${index}][document_id]`,
            element.document_id
          );
          formData.append(
            `documents[${index}][original_file_name]`,
            element.original_file_name
          );
        }

        if (element.file) {
          formData.append(`documents[${index}][file]`, element.file);
        }
      });
      // if (this.entity.deletedDocuments && this.entity.deletedDocuments.length) {
      //   this.entity.deletedDocuments.forEach((element, index) => {
      //     formData.append(`deleted_documents[${index}]`, element);
      //   });
      // }
      // this.entity.documents.forEach((element, index) => {
      //   Object.keys(element).forEach((key) => {
      //     if (element[key] != null)
      //       formData.append(`documents[${index}][${key}]`, element[key]);
      //   });
      // });
      this.$http
        .post(
          "venues/b2b" + (this.entity.id != null ? `/${this.entity.id}` : ""),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let message = response.data.message;
            this.showSuccess(message);
            this.hideLoader();
            if (flag) {
              this.loadPartners();
              this.editMode = false;
              this.addEntityDialogue = false;
              //   this.trainerDetails(this.trainer.id, true);
              //   this.trainerPackageDialog = false;
            } else {
              this.loadPartners();
              this.addEntityDialogue = false;
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};

export default b2b;
