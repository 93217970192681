const b2bFields = {
  data() {
    return {
      field: {
        phone: {
          slug: "phone",
          is_visible: true,
          is_required: true,
        },
        b2b_type_id: {
          slug: "entity_type",
          is_visible: true,
          is_required: true,
        },
        entity_name: {
          slug: "entity_name",
          is_visible: true,
          is_required: true,
        },
        contact_person_name: {
          slug: "contact_person_name",
          is_visible: true,
          is_required: true,
        },
        email: {
          slug: process.env.VUE_APP_EMAIL_FIELD,
          is_visible: true,
          is_required: true,
        },
        description: {
          slug: "description",
          is_visible: true,
          is_required: false,
        },
        profile_image: {
          slug: "profile_image",
          is_visible: true,
          is_required: false,
        },
        trn_no: {
          slug: "trn_no",
          is_visible: true,
          is_required: false,
        },
        trade_license_no: {
          slug: "trade_license_no",
          is_visible: true,
          is_required: false,
        },
      },
      documentFields: [],
    };
  },
  computed: {
    phoneRule() {
      const rules = [];
      if (this.field.entity_name.is_required) {
        const rule = (v) => !!v || "Phone is required";
        rules.push(rule);
      }
      return rules;
    },
    entityTypeRule() {
      const rules = [];
      if (this.field.b2b_type_id.is_required) {
        const rule = (v) => !!v || "Entity Type is required";
        rules.push(rule);
      }
      return rules;
    },
    descriptionRule() {
      const rules = [];
      if (this.field.description.is_required) {
        const rule = (v) => !!v || "Description is required";
        rules.push(rule);
      }
      return rules;
    },
    trnRule() {
      const rules = [];
      if (this.field.trn_no.is_required) {
        const rule = (v) => !!v || "TRN is required";
        rules.push(rule);
      }
      return rules;
    },
    trRule() {
      const rules = [];
      if (this.field.trade_license_no.is_required) {
        const rule = (v) => !!v || "Trade license number is required";
        rules.push(rule);
      }
      return rules;
    },
    entityNameRule() {
      const rules = [];
      if (this.field.entity_name.is_required) {
        const rule = (v) => !!v || "Entity Name is required";
        rules.push(rule);
      }
      return rules;
    },
    contactPersonName() {
      const rules = [];
      if (this.field.contact_person_name.is_required) {
        const rule = (v) => !!v || "Contact Person Name is required";
        rules.push(rule);
      }
      return rules;
    },
    emailRule() {
      const rules = [];
      if (this.field.email.is_required) {
        const rule = (v) => !!v || "Email is required";
        rules.push(rule);
      }
      const rule = (v) => !v || /.+@.+\..+/.test(v) || "E-mail must be valid";
      rules.push(rule);
      return rules;
    },
    imageRule() {
      const rules = [];
      if (this.field.image.is_required && !this.bookingForm.image_path) {
        const rule = (v) => !!v || "Image is required";
        rules.push(rule);
      }
      return rules;
    },
    // venueServiceConfiguration() {
    //   return this.$store.getters.getConfigurationByVenueServiceId(
    //     this.venue_service_id
    //   );
    // },
    // venueServiceDocuments() {
    //   return this.$store.getters.getDocumentsByVenueServiceId(
    //     this.venue_service_id
    //   );
    // },
  },
  methods: {
    setFieldConfigurations() {
      let data = [];
      if (this.venue_service_id) {
        data = this.$store.getters.getBookingFieldsByVenueServiceId(
          this.venue_service_id
        );
      }
      if (this.productType) {
        data = this.$store.getters.getBookingFieldsByProduct(this.productType);
      }

      data.forEach((field) => {
        if (field.slug == this.field.phone.slug) {
          this.field.phone.is_visible = field.is_visible == 1 ? true : false;
          this.field.phone.is_required = field.is_required == 1 ? true : false;
        } else if (field.slug == this.field.entity_name.slug) {
          this.field.entity_name.is_visible =
            field.is_visible == 1 ? true : false;
          this.field.entity_name.is_required =
            field.is_required == 1 ? true : false;
        } else if (field.slug == this.field.contact_person_name.slug) {
          this.field.contact_person_name.is_visible =
            field.is_visible == 1 ? true : false;
          this.field.contact_person_name.is_required =
            field.is_required == 1 ? true : false;
        } else if (field.slug == this.field.email.slug) {
          this.field.email.is_visible = field.is_visible == 1 ? true : false;
          this.field.email.is_required = field.is_required == 1 ? true : false;
        } else if (field.slug == this.field.profile_image.slug) {
          this.field.profile_image.is_visible =
            field.is_visible == 1 ? true : false;
          this.field.profile_image.is_required =
            field.is_required == 1 ? true : false;
        } else if (field.slug == this.field.b2b_type_id.slug) {
          this.field.b2b_type_id.is_visible =
            field.is_visible == 1 ? true : false;
          this.field.b2b_type_id.is_required =
            field.is_required == 1 ? true : false;
        } else if (field.slug == this.field.description.slug) {
          this.field.description.is_visible =
            field.is_visible == 1 ? true : false;
          this.field.description.is_required =
            field.is_required == 1 ? true : false;
        } else if (field.slug == this.field.trn_no.slug) {
          this.field.trn_no.is_visible = field.is_visible == 1 ? true : false;
          this.field.trn_no.is_required = field.is_required == 1 ? true : false;
        } else if (field.slug == this.field.trade_license_no.slug) {
          this.field.trade_license_no.is_visible =
            field.is_visible == 1 ? true : false;
          this.field.trade_license_no.is_required =
            field.is_required == 1 ? true : false;
        }
      });
      this.$forceUpdate();
    },
    setDocumentFields() {
      this.documentFields = null;
      if (this.productType) {
        this.documentFields = JSON.parse(
          JSON.stringify(
            this.$store.getters
              .getFieldDocumentsByProduct(this.productType)
              .filter((x) => x.is_visible)
          )
        );
      }
    },
  },
};

export default b2bFields;
