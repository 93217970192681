<template>
  <v-dialog v-model="addEntityDialogue" scrollable width="60%" max-width="823px">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title class="border-bottom">
          <div class=" w-full">
            <div class="d-flex justify-space-between align-center">
              <p class="mb-0 font-medium">
                {{entity.id!=null ? 'Update Entity':'Add Entity'}}
              </p>
              <v-btn class="shadow-0" fab x-small @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card-text class="mt-4">

          <v-row>
            <v-col v-if="field.profile_image.is_visible" md="4">
              <image-uploader
                  @upload="
                      (data) => {
                        entity.profile_image = data;
                      }
                    "
                  :image_path="entity.image_path"
                  :height="200"
                  defaultImage="user"
                  @remove="
                      () => {
                        entity.profile_image = null;
                      }
                    "
                  text="Entity picture"
                  :show-guide="false"
                  message-text=""
                  ref="image_upload"
              ></image-uploader>
            </v-col>
            <v-col md="8">
              <v-row>
                <v-col cols="12" md="6" sm="6">
                  <label for="">
                    Entity name{{ field.entity_name.is_required ? '*' : '' }}
                  </label>
                  <v-text-field
                      v-model="entity.entity_name"
                      :rules="entityNameRule"
                      background-color="#fff"
                      outlined
                      dense
                      hide-details="auto"
                      class="q-text-field shadow-0"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <label for="">
                    Contact Person name{{ field.contact_person_name.is_required ? '*' : '' }}
                  </label>
                  <v-text-field
                      v-model="entity.contact_person_name"
                      :rules="contactPersonName"
                      background-color="#fff"
                      outlined
                      required
                      hide-details="auto"
                      dense
                      class="q-autocomplete shadow-0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <label for="">Phone{{ field.phone.is_required ? '*' : '' }}</label>
                  <mobile-number-field
                      v-model="entity.phone"
                      :rules="phoneRule"
                      :dense="true"
                      :outlined="true"
                      :variant="1"
                  />
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <label for="">
                    Email{{ field.email.is_required ? '*' : '' }}
                  </label>
                  <v-text-field
                      v-model="entity.email"
                      :rules="emailRule"
                      background-color="#fff"
                      outlined
                      required
                      dense
                      hide-details="auto"
                      class="q-text-field shadow-0"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" sm="6">
              <label for="">
                Type{{ field.b2b_type_id.is_required ? '*' : '' }}
              </label>
              <v-select
                  v-model="entity.b2b_type_id"
                  :items="b2bTypes"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="entityTypeRule"
                  background-color="#fff"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
              ></v-select>
            </v-col>
            <v-col v-if="field.trn_no.is_visible" cols="12" md="4" sm="6">
              <label for="">
                TRN number{{ field.trn_no.is_required ? '*' : '' }}
              </label>
              <v-text-field
                  v-model="entity.trn_number"
                  :rules="trnRule"
                  background-color="#fff"
                  outlined
                  dense
                  hide-details="auto"
                  class="q-text-field shadow-0"
              ></v-text-field>
            </v-col>
            <v-col
                v-if="field.trade_license_no.is_visible"
                cols="12"
                md="4"
                sm="6"
            >
              <label for="">
                Trade License Number{{ field.trade_license_no.is_required ? '*' : '' }}
              </label>
              <v-text-field
                  v-model="entity.trade_license_no"
                  :rules="trRule"
                  background-color="#fff"
                  outlined
                  dense
                  hide-details="auto"
                  class="q-text-field shadow-0"
              ></v-text-field>
            </v-col>
            <v-col v-if="isB2E" cols="12" md="4" sm="6">
              <label for="">
                City
              </label>
              <v-select
                  v-model="entity.city_id"
                  :items="b2bCities"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'City is required']"
                  background-color="#fff"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
              ></v-select>
            </v-col>
            <v-col v-if="isB2E" cols="12" md="4" sm="6">
              <label for="">
                Sector
              </label>
              <v-select
                  v-model="entity.sector"
                  :items="sectors"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'Sector is required']"
                  background-color="#fff"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
              ></v-select>
            </v-col>
            <v-col v-if="isB2E" cols="12" md="4" sm="6">
              <label for="">
                Institution Type
              </label>
              <v-select
                  v-model="entity.institution_type"
                  :items="institutionType"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'Institution Type is required']"
                  background-color="#fff"
                  outlined
                  dense
                  hide-details="auto"
                  class="q-autocomplete shadow-0"
              ></v-select>
            </v-col>
            <v-col
                v-if="field.description.is_visible"
                cols="12"
            >
              <label for="">
                Description{{ field.description.is_required ? '*' : '' }}
              </label>
              <v-textarea
                  v-model="entity.description"
                  :rules="descriptionRule"
                  background-color="#fff"
                  outlined
                  dense
                  hide-details="auto"
                  class="q-text-field shadow-0"
                  rows="5"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col lg="3" md="3"  align-self="end">
              <v-checkbox
                  v-model="entity.allow_credit"
                  false-value="0"
                  class="mt-1 qp-custom-checkbox"
                  label="Allow Credit Limit"
                  hide-details="auto"
                  true-value="1"
                  style="min-height: 40px !important;height: auto !important;"
              ></v-checkbox>
            </v-col>
            <v-col
                v-if="entity.allow_credit === '1'"
                cols="3"
                md="3"
                sm="3"
            >
              <label for="">
                Credit Limit
              </label>
              <v-text-field
                  v-model="entity.credit_limit"
                  :prefix="currencyCode"
                  background-color="#fff"
                  outlined
                  required
                  dense
                  hide-details="auto"
                  class="q-text-field shadow-0"
              ></v-text-field>
            </v-col>
            <v-col lg="3" md="3" align-self="end">
              <v-checkbox
                  v-model="entity.enable_commission"
                  false-value="0"
                  class="qp-custom-checkbox"
                  label="Enable Commission"
                  hide-details="auto"
                  true-value="1"
                  style="min-height: 40px !important;height: auto !important;"
              ></v-checkbox>
            </v-col>
            <v-col v-if="entity.enable_commission == '1'" lg="3" md="3">
              <label for="">
                Reseller Commission
              </label>
              <v-text-field
                  v-model="entity.commission_percentage"
                  :rules="percentageRules"
                  background-color="#fff"
                  outlined
                  required
                  type="text"
                  dense
                  hide-details="auto"
                  class="q-text-field shadow-0"
              >
                <template #append>
                  <PercentageIcon class="mt-1"/>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <div v-if="documentFields.length" class="text-lg text-blue font-medium mt-6 mb-2">
            Documents
          </div>
          <div v-if="documentFields.length">
            <v-row>
              <v-col
                  v-for="(documentField, index) in documentFields"
                  :key="index"
                  cols="12"
                  md="6"
                  sm="6"
              >
                <label for="">
                  {{ documentField.name }}
                </label>
                <v-file-input
                    v-model="documentField.file"
                    :rules="documentUploadRule(documentField)"
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                    outlined
                    prepend-icon=""
                    validate-on-blur
                    :placeholder="getFileLabel(documentField)"
                />
                <v-btn v-if="documentField.uploaded_file_path" :ripple="false" class="no-hover-effect px-0" text x-small
                       @click="openFile(documentField.uploaded_file_path)">
                  Download {{ documentField.name }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" text @click="close">Close</v-btn>
          <v-btn
              v-if="checkWritePermission($modules.b2b.partner.slug)"
              class="ma-2 white--text teal-color"
              @click="addOrEditEntity"
          >
            {{ entity.id != null ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import b2bFields from "@/mixins/b2bFieldValidation";
import constants from "@/utils/constants";
import b2b from "@/mixins/b2b";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import MobileNumberField from "@/components/Fields/MobileNumberField.vue";
import PercentageIcon from '@/assets/images/partners/percentage.svg';

export default {
  name: "AddEditPartner",
  components: {MobileNumberField, ImageUploader,PercentageIcon},
  mixins: [b2b, b2bFields],
  props: {
    addEntityDialogue: {
      type: Boolean,
      default: false,
    },
    partner: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    if (this.partner) {
      this.entity = this.partner
    }
    if (this.$store.getters.getB2bTypes.status == false) {
      this.$store.dispatch("loadB2bTypes");
    }
    if (this.$store.getters.getB2bCities.status == false) {
      this.$store.dispatch("loadB2bCities");
    }
    if (!this.$store.getters.configurationStatus("B2B")) {
      this.$store.dispatch("loadConfigurations", "B2B").then(() => {
        this.setDocumentFields();
        this.setFieldConfigurations();
        this.setDocuments();
      });
    } else {
      this.setDocumentFields();
      this.setFieldConfigurations();
      this.setDocuments();
    }

  },
  data() {
    return {
      valid: true,
      entity: {},
      isB2E: false,
      sectors: constants.sectors,
      institutionType: constants.institutionType,
      productType: "B2B",
      percentageRules: [
        (value) => {
          if (value && !/^\d+(\.\d+)?%?$/.test(value)) {
            return "Invalid percentage format";
          }
          return true;
        },
      ],
    }
  },
  watch: {
    'entity.b2b_type_id'(val) {
      this.isB2E = val === this.b2bTypes.find((ele) => {
        return ele.name === 'B2E'
      })?.id;
    },
    addEntityDialogue(val) {
      if (!val) {
        this.entity = {}
      }
    }
  },
  methods: {
    addOrEditEntity(e) {
      if (e != null) {
        e.preventDefault();
        if (!this.$refs.form.validate()) {
          this.showError("Please fill all required fields");
          return;
        }
      }
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let formData = new FormData();
      this.showLoader();
      if (
          this.entity.profile_image != null &&
          typeof this.entity.profile_image != "string"
      ) {
        formData.append("profile_image", this.entity.profile_image);
      }
      if (this.entity.entity_name){
        formData.append("entity_name", this.entity.entity_name);
      }
      if (this.entity.enable_commission){
        formData.append("enable_commission", this.entity.enable_commission);
      }
      if (this.entity.enable_commission == 1) {
        formData.append(
            "commission_percentage",
            this.entity.commission_percentage
        );
      } else {
        formData.append("commission_percentage", 0);
      }
      if(this.entity.b2b_type_id){
        formData.append("b2b_type_id", this.entity.b2b_type_id);
      }
      if(this.entity.contact_person_name){
        formData.append("contact_person_name", this.entity.contact_person_name);
      }
      if(this.entity.phone){
        formData.append("phone", this.entity.phone);
      }
      if(this.entity.trn_number){
        formData.append("trn_number", this.entity.trn_number);
      }
      if(this.entity.trade_license_no){
        formData.append("trade_license_no", this.entity.trade_license_no);
      }
      if(this.entity.description){
        formData.append("description", this.entity.description);
      }
      if(this.entity.city_id){
        formData.append("city_id", this.entity.city_id);
      }
      if(this.entity.sector){
        formData.append("sector", this.entity.sector);
      }
      if(this.entity.institution_type){
        formData.append("institution_type", this.entity.institution_type);
      }
      if(this.entity.email){
        formData.append("email", this.entity.email);
      }
      if(this.entity.allow_credit){
        formData.append("allow_credit", this.entity.allow_credit);
      }
      if(this.entity.credit_limit){
        formData.append("credit_limit", this.entity.credit_limit);
      }

      let document = [];
      this.documentFields.forEach((documentField) => {
        if (documentField.file || documentField.isEdit) {
          document.push(documentField);
        }
      });

      document.forEach((element, index) => {
        formData.append(`documents[${index}][name]`, element.name);
        formData.append(`documents[${index}][id]`, element.id);
        if (element.isEdit) {
          formData.append(`documents[${index}][file_path]`, element.file_path);
          formData.append(
              `documents[${index}][document_id]`,
              element.document_id
          );
          formData.append(
              `documents[${index}][original_file_name]`,
              element.original_file_name
          );
        }

        if (element.file) {
          formData.append(`documents[${index}][file]`, element.file);
        }
      });
      this.$http
          .post(
              "venues/b2b" + (this.entity.id != null ? `/${this.entity.id}` : ""),
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data; boundary=${form._boundary}",
                },
              }
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              let message = response.data.message;
              this.showSuccess(message);
              this.hideLoader();
              this.$emit("close");
              this.$emit('refresh')
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    close() {
      this.$emit("close");
      this.entity = {}
    },

    documentUploadRule(field) {
      const rules = [];
      if (field.is_required && !field.isEdit) {
        const rule = (v) => !!v || "Document is required";
        rules.push(rule);
      }
      return rules;
    },
    getFileLabel(documentField) {
      if (!this.entity){
        return "Upload File";
      }
      if (!this.entity.documents || this.entity.documents.length === 0){
        return "Upload File";
      }
      const file = this.entity.documents.find((ele) => {
        return ele.felid_id.toString() === documentField.id.toString();
      });
      if (file) {
        return file.original_file_name.slice(0, 15) + "..."+file.original_file_name.slice(-5);
      }
      return documentField.file?.name ? documentField.file.name : "Upload";
    },
    setDocuments(){
      if(!this.entity){
        return;
      }
      if (this.entity.documents) {
        this.entity.documents.forEach((document) => {
          this.documentFields.forEach((documentField) => {
            if (documentField.id == document.felid_id) {
              documentField.original_file_name =
                  document.original_file_name;
              documentField.file_path = document.file_path;
              documentField.document_id = document.id;
              documentField.isEdit = true;
            }
          });
        });
      }
    }
  },
  computed: {
    b2bTypes() {
      return this.$store.getters.getB2bTypes.data;
    },
    b2bCities() {
      return this.$store.getters.getB2bCities.data;
    },
  }
}
</script>

<style lang="scss" scoped>

</style>